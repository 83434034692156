import ScrollReveal from 'scrollreveal';

export default {
  mounted() {
    const scrollReveal = ScrollReveal();

    setTimeout(() => {
      scrollReveal.reveal('.project .tags .tag', {
        duration: 1000,
        easing: 'ease-in-out',
        rotate: { z: 160 }
      });
    }, 1000);

    scrollReveal.reveal('.project .top .con, .project .brief .info, .project .people .content div, .project .need .txt', {
      distance: '400px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'right'
    });

    scrollReveal.reveal('.project .advantage .content div, .project .question div, .project .sign .detail, .project .mid-banner .con, .project .advantage .con, .project .need .con', {
      distance: '400px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'left'
    });
  },
  beforeDestroy() {
    ScrollReveal().destroy();
  }
};