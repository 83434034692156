<template>
  <div class="project australia">
    <div class="top">
      <img
        :src="`${QNHost}/Project/Australia1/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/Project/Australia1/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5>188A商业创新类签证</h5>
        <p class="time"></p>
        <p class="tip">简单创业 • 快速移民</p>
      </div>
      <div class="tags">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="sign">
      <div class="con">
        <div class="detail">
          <h3>188A商业创新类签证</h3>
          <img
            :src="`${QNHost}/Project/Australia1/09.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <p>中小企业家的福音</p>
          <div>
            <p>
              188A商业创新类签证属于澳洲商业技术移民，通常也被称为澳洲投资移民、澳洲商业移民等。此签证申请对审计要求相对较松、注重公司的营业额，对投资金额要求不高，投资方式可灵活变通。
            </p>
            <br />
            <p>
              188A商业创新类签证是有效期为4年的临时居留签证，4年后，满足一定的条件，就可以申请转为澳洲永久居民签证（888签证）。
            </p>
          </div>
        </div>
        <div class="imgs">
          <img
            class="pc-show"
            :src="`${QNHost}/Project/Australia1/02.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="mobile-show"
            :src="`${QNHost}/Project/Australia1/mobile/02.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="pc-show"
            :src="`${QNHost}/Project/Australia1/03.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="mobile-show"
            :src="`${QNHost}/Project/Australia1/mobile/03.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
      </div>
    </div>

    <div class="mid-banner">
      <div class="background-media"></div>
      <div class="con">
        <h5>适宜人群</h5>
        <p>中小型企业主或者大型企业管理者（需拥有股份）</p>
        <i>HOPEWINNER</i>
      </div>
    </div>

    <div class="advantage">
      <img
        :src="`${QNHost}/Project/Australia1/05.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5 style="color:#ffffff">项目优势</h5>
        <img
          :src="`${QNHost}/Project/Australia1/08.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <br class="mobile-show" />
        <p>1、条件宽松，无学历、语言要求</p>
        <p>2、资产及投资要求低</p>
      </div>
    </div>

    <div class="need">
      <div class="con">
        <h5>申请要求</h5>
        <div class="lists">
          <p>1、主申请人55岁以下</p>
          <p>2、申请人有直接参与经营及管理企业的成功经历</p>
          <p>3、申请人为企业股东，持股30%以上；若是上市公司需持股10%以上</p>
          <p>4、商业净资产及个人净资产达到80万澳币</p>
          <p>5、申请前4年有2年企业营业额达到50万澳币</p>
          <p>6、评分达到65分</p>
          <p>7、主副申请人可以互换完成创业和居住要求</p>
        </div>
      </div>
      <div class="imgs">
        <img
          class="pc-show"
          :src="`${QNHost}/Project/Australia1/06.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile-show"
          :src="`${QNHost}/Project/Australia1/mobile/06.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile-show"
          :src="`${QNHost}/Project/Australia1/mobile/07.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p>Q：如果我的第一个生意做到一半，想换成新的生意继续可以吗？</p>
        <p>
          A：移民法规定第一个生意做的时间不少于6个月，并且和第二个生意的间隔时间不超过45天，那么生意开始的时间还是可以从第一个生意开始时间算起。
        </p>
        <br />
        <p>Q：可以选择什么类型的生意？</p>
        <p>
          A：按照移民局和州政府的规定，非法的生意（毒品麻醉品），仿货水货，大宗商品（铁矿石，铜矿石，煤矿，羊毛）废铜废铁出口的生意、物流快递点生意、小型房地产开发生意、房屋出租生意、股票外汇交易不可以申请888签证，除此之外的其它生意都是可以的。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
@import "../../assets/project.scss";
.background-media {
  background-image: url($QNHost + "/Project/Australia1/3.jpg");
}
.time {
  // font-family: 'SourceHanSansCN-ExtraLight';
  position: relative;
  &:after {
    position: absolute;
    content: " ";
    width: 3%;
    left: 48.4%;
    bottom: 0px;
    border-bottom: 8px solid #ffffff;
  }
}
</style>
